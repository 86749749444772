import React, { KeyboardEvent } from 'react';
import axios from 'axios';
import { Grid, Link, Typography } from '@material-ui/core';
import StyledTextField from '../styled/StyledTextField';
import PrimaryButton from '../styled/PrimaryButton';
import Spacer from '../styled/Spacer';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { AuthType } from '../../types/TypeRegistry';

interface Props {
    username: string,
    setUsername: (username: string) => void,
    setAuthType: (authType: AuthType) => void,
    handleOpenModal: () => void,
    setLoading: (loading: boolean) => void,
    errorMessage: string,
    setErrorMessage: (errorMessage: string) => void
}

export interface UserInfo {
    saml: boolean,
}

/**
 * Displays a field for username. Upon collecting the username, the server is contacted in order to 
 * help determine what type of authentication flow to proceed with.
 * 
 * @param props 
 *     username (string): parent managing state of username, must come down as prop
 *     setUsername (callback): parent managing username state, setter must come down as prop
 *     setAuthType (callback): parent managing authType state, setter must come down as prop
 *     handleOpenModal (callback): callback for opening the 'forgot info' modal
 *     setLoading (callback): parent managing loading state, setter must come down as prop
 *     errorMessage (string): error message to be displayed under username field
 *     setErrorMessage (callback): parent managing errorMessage state, setter must come down as prop
 * 
 */
function UsernameScreen(props: Props): JSX.Element {

    const { username, setUsername, setAuthType, handleOpenModal, setLoading,
        errorMessage, setErrorMessage } = props;

    /**
     * Checks if the keypress was 'Enter', if so, simulates clicking the "Next" button
     * @param e the keyboard event
     */
    function handlePressEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter') {
            handleNext();
        }
    }

    /**
     * Action to be executed when "Next" button is clicked.
     * Validates username field. If it passes validation, begin flow for determining authentication type.
     */
    function handleNext(): void {
        if (username.length < 5 || username.length > 75) {
            setErrorMessage('Username must be between 5 and 75 characters.');
        } else {
            determineAuthType();
        }
    }

    /**
     * Makes a call to the dnsme-auth to login information for the provided username.
     * Uses this info to set the appropriate auth type.
     */
    function determineAuthType(): void {
        const requestUri = `auth/user/info?username=${encodeURIComponent(username)}`;
        setLoading(true);
        axios.get<UserInfo>(requestUri)
            .then((result) => {
                const userInfo = result.data;
                if (userInfo.saml) {
                    setAuthType("saml");
                } else {
                    setAuthType("basic");
                }
            }).catch(err => {
                console.error(err);
                setErrorMessage('Server error. Please try again.');
            }).finally(() => setLoading(false));
    }

    return <span onKeyPress={handlePressEnter}>
        <Link href="#" onClick={handleOpenModal} align="right"><Typography>Forgot your username?</Typography></Link>
        <Grid item>
            <StyledTextField
                autoFocus
                variant="outlined"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth color="primary"
                placeholder="Enter Username"
                label="Username"
                margin="dense"
                error={errorMessage !== ''}
                helperText={errorMessage}
            />
        </Grid>
        <Grid item>
            <PrimaryButton onClick={handleNext} fullWidth variant="contained" endIcon={<ArrowRightAltIcon />}>Next</PrimaryButton>
        </Grid>
        <Grid item>
            <Spacer orientation="vertical" size={105} />
        </Grid>
    </span>
}

export default UsernameScreen;
