import React, { KeyboardEvent, useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StyledTextField from '../styled/StyledTextField';
import SecondaryButton from '../styled/SecondaryButton';
import PrimaryButton from '../styled/PrimaryButton';
import Loading from '../util/Loading';
import { BasicAuthResponse } from './PasswordScreen';
import { ServerAuthType } from '../../types/TypeRegistry';

interface Props {
    username: string,
    password: string,
    open: boolean,
    handleReset: () => void,
    handleClose: () => void
}

/**
 * Modal which prompts user for email in order to help retrieve/reset username/password information.
 * @param props 
 */
function EmergencyKeyModal(props: Props): JSX.Element {
    const { username, password, open, handleReset, handleClose } = props;

    const [emergencyKey, setEmergencyKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // If an error message is present, errorStatus is true
    const errorStatus = (errorMessage !== '');

    const styles = useStyles();

    /**
     * To be executed when the emergency key modal is closed. Resets pertinent pieces of state
     */
    function closeModal(): void {
        handleClose();
        setEmergencyKey('');
        setErrorMessage('');
        if (formSubmitted) {
            handleReset();
        }
        setFormSubmitted(false);
    }

    /**
    * Contacts the authentication server to attempt a login. If login is successful, we route to the
    * authenticated landing page. Otherwise, we set the error message appropriately.
    */
    function handleSend(): void {

        const loginForm = {
            principal: username,
            secret: password,
            authenticatorCode: emergencyKey,
            captchaResponse: '',
            authenticationType: ServerAuthType.EMERGENCY
        }

        setLoading(true);

        axios.post<BasicAuthResponse>('auth/authenticate/login', loginForm)
            .then(() => {
                setLoading(false);
                setFormSubmitted(true);
            })
            .catch((err) => {
                setLoading(false);

                if (err.response.data !== undefined && err.response.data.message !== undefined) {
                    setErrorMessage(err.response.data.message);
                }
                else {
                    setErrorMessage('Server error. Please try again.');
                }
            });
    }

    /**
     * Checks if the keypress was 'Enter', if so, simulates clicking the "Login" button
     * If the "Back" button is selected, this function does nothing and the back button is clicked instead
     * 
     * @param e the keyboard event
     */
    function handlePressEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter'
            && document.activeElement !== document.getElementById('cancelBtn')) {

            handleSend();
        }
    }

    return <Dialog
        open={open}
        onClose={closeModal}
        onKeyPress={handlePressEnter}
    >
        {loading
            ? <Loading />
            : <span className={styles.contents}>
                <Grid container justify="center" direction="column" spacing={2}>
                    <Grid item>
                        <DialogTitle><b>Deactivate Two-Factor Authentication</b></DialogTitle>
                    </Grid>
                    {renderContents()}
                    <Grid item>
                        <Typography>Don&apos;t have access to your authenticator or your emergency key? Email us at <Link href="mailto:sales@dnsmadeeasy.com">sales@dnsmadeeasy.com</Link></Typography>
                    </Grid>
                </Grid>
            </span>
        }

    </Dialog>

    function renderContents(): JSX.Element {

        if (!formSubmitted) {
            return <>
                <Grid item>
                    <Typography>Enter your <b>emergency code</b> to access your account. This will deactivate two-factor authentication and you will be able to login with your username/password.</Typography>
                </Grid>
                <Grid item>
                    <StyledTextField
                        autoFocus
                        variant="outlined"
                        fullWidth color="primary"
                        placeholder="Enter Emergency Code"
                        label="Emergency Code"
                        margin="dense"
                        value={emergencyKey}
                        onChange={(e) => setEmergencyKey(e.target.value)}
                        error={errorStatus}
                        helperText={errorMessage}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <SecondaryButton
                                id="backBtn"
                                onClick={closeModal}
                                type="button"
                                fullWidth
                                variant="contained"
                            >
                                Cancel
                        </SecondaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                onClick={handleSend}
                                type="button"
                                fullWidth variant="contained"
                            >
                                Send
                        </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        } else {
            return <>
                <Grid item className={styles.successMessageContainer}>
                    <Typography align="center" className={styles.successMessage}>Two-Factor Authentication has been successfully disabled for your account. You may now use your username/password to login.</Typography>
                </Grid>
            </>
        }
    }
}

const useStyles = makeStyles({
    contents: {
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    },
    successMessageContainer: {
        backgroundColor: 'rgba(229, 244, 227, 1)'
    },
    successMessage: {
        padding: '1rem',
    }
});

export default EmergencyKeyModal;