import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';


function StyledTextField(props: OutlinedTextFieldProps): JSX.Element {
    const styles = useStyles();
    return <TextField {...props} className={styles.textField} />
}

const useStyles = makeStyles({
    textField:
    {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(0, 91, 153, 0.29)',
        },
        marginBottom: '1.5rem',
    }
});

export default StyledTextField;