import React, { useState } from 'react';
import { Grid, Typography, Link, makeStyles } from '@material-ui/core';
import UsernameScreen from '../login/UsernameScreen';
import PasswordScreen from '../login/PasswordScreen';
import MultiFactorScreen from '../login/MultiFactorScreen'
import { AuthType } from '../../types/TypeRegistry';
import SamlWindow from './SamlWindow';
import logo from '../../resources/img/logo.png'
import ForgotInfoModal from './ForgotInfoModal';
import Loading from '../util/Loading';

/**
 * Starting point for all contents of the login form itself.
 * Maintains state for username, authType (type of authentication i.e basic, saml, etc), 
 * and the forgot info modal's open/closed status.
 * Renders a username screen, password screen, or saml screen based on type of authentication
 */
function LoginWindow(): JSX.Element {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authType, setAuthType] = useState<AuthType>('none');
    const [forgotModalOpen, setForgotModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState('');

    const styles = useStyles();

    /**
     * Resets the login form entirely. Username and auth type are cleared.
     */
    function resetForms(): void {
        setUsername('');
        setPassword('');
        setAuthType('none');
    }

    return (
        <>
            {loading ? <Loading /> : null}
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="space-around"
                className={styles.container}
                align-content-xs-center
            >
                <Grid item>
                    <img alt="DNS Made Easy" src={logo} />
                </Grid>
                <Grid item style={{ width: '50%' }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container direction="row">
                                <Grid item xs={12} sm={4}>
                                    <Typography align="left" className={styles.formHeader} variant="h4">Login</Typography>
                                </Grid>
                                {renderLoggingInAs()}
                            </Grid>
                        </Grid>
                        {renderFormContents()}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Typography color="secondary">Not registered? <Link href={`${process.env.REACT_APP_CP_URL}/account/new`}>Create an account!</Link></Typography>
                    </Grid>
                </Grid>
                <ForgotInfoModal open={forgotModalOpen} handleClose={() => setForgotModalOpen(false)} />
            </Grid>
        </>

    );

    /**
     * Uses the authtype to determine which authentication screen to display.
     * If still undetermined ('none'), display username screen to collect username.
     * If basic, display password screen to collect a password.
     * If mfa, display password screen with an additional field for authenticator code
     * If saml, display saml screen to route to appropriate identity provider
     */
    function renderFormContents(): JSX.Element {
        switch (authType) {
            case 'none':
                return <UsernameScreen
                    username={username}
                    setUsername={setUsername}
                    errorMessage={usernameError}
                    setErrorMessage={setUsernameError}
                    setAuthType={setAuthType}
                    handleOpenModal={() => setForgotModalOpen(true)}
                    setLoading={setLoading}
                />
            case 'basic':
                return <PasswordScreen
                    username={username}
                    password={password}
                    setPassword={setPassword}
                    setAuthType={setAuthType}
                    handleReset={resetForms}
                    handleOpenModal={() => setForgotModalOpen(true)}
                    setLoading={setLoading}
                />
            case 'mfa':
                return <MultiFactorScreen
                    username={username}
                    password={password}
                    handleReset={resetForms}
                    setLoading={setLoading}
                />
            case 'saml':
                return <SamlWindow
                    email={username}
                    setErrorMsg={setUsernameError}
                    setAuthType={setAuthType}
                    setLoading={setLoading}
                />
            default:
                // TODO Handle error
                return <span />;
        }
    }

    /**
     * Displays a sub-heading for basic/mfa screens which shows username which is being authenticated
     */
    function renderLoggingInAs(): JSX.Element {
        if (authType === "basic" || authType === "mfa") {
            return <Grid item xs={12} sm={8}>
                <Typography align="right">Logging in as: <b>{username}</b></Typography>
            </Grid>;
        }

        return <span />;
    }
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        height: '100%',
    },
    formHeader: {
        paddingBottom: '2rem'
    }
});

export default LoginWindow;