import { fade, emphasize, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import spinner from '../../resources/img/loading-spinner.svg';

/**
 * Fades out the containing screen and displays a spinner/loading icon.
 */
function Loading(): JSX.Element {
    const styles = useStyles();

    return <div className={styles.backdrop}>
        <img src={spinner} className={styles.icon} />
    </div>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    backdrop: {
        width: "100%",
        height: "100%",
        overflow: "auto", /* Enable scroll if needed */
        backgroundColor: fade(emphasize(theme.palette.background.paper, 0.6), 0.5),
        display: "flex",
        alignItems: 'center',
    },
    icon: {
        flex: 1,
        width: 64,
        height: 64
    }
}));


export default Loading;