import { Grid, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import LogoCollage from "./LogoCollage";

function ClientShowcase(): JSX.Element {
  const styles = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="space-around"
      className={styles.container}
    >
      <Grid item>
        <Typography color="textSecondary" variant="h3" className={styles.text}>
          <b>We keep global brands online.</b>
        </Typography>
      </Grid>

      <Grid item>
        <LogoCollage />
      </Grid>

      <Grid item>
        <Typography
          align="center"
          color="textPrimary"
          variant="h4"
          className={styles.text}
        >
          <b>Fast and reliable DNS has never been easier!</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: "white",
      [theme.breakpoints.only("xs")]: {
        fontSize: 28,
      },
    },
    heart: {
      color: "white",
      fontSize: "60px",
      marginTop: "10px",
    },
    container: {
      display: "flex",
      height: "100%",
      paddingBottom: "1rem",
      whiteSpace: "pre-wrap",
    },
  })
);

export default ClientShowcase;
