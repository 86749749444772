import React, { useState, KeyboardEvent } from 'react';
import axios from 'axios';
import { Grid, Link, Typography } from '@material-ui/core';
import StyledTextField from '../styled/StyledTextField';
import PrimaryButton from '../styled/PrimaryButton';
import SecondaryButton from '../styled/SecondaryButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { ServerAuthType, AuthType } from '../../types/TypeRegistry';

interface Props {
    username: string,
    password: string,
    setPassword: (password: string) => void,
    setAuthType: (authType: AuthType) => void,
    handleReset: () => void,
    handleOpenModal: () => void,
    setLoading: (loading: boolean) => void
}
export interface BasicAuthResponse {
    needsTwoFactor: boolean,
    oneTimeKey: string
}

/**
 * Displays a field for password
 * When 'Login' button is clicked, collected info is sent to authentication server to attempt a login
 * 
 * @param props 
 *     username (string): username that was entered at the previous screen
 *     password (string): user's password for authentication
 *     setPassword (callback): parent managing password state, setter must come down as prop
 *     handleReset (callback): resets form, to be executed when 'cancel' is clicked
 *     handleOpenModal (callback): opens forgot info modal
 *     setLoading (callback): parent managing loading state, setter must come down as prop
 */
function PasswordScreen(props: Props): JSX.Element {

    const { username, password, setPassword, setAuthType, handleReset, handleOpenModal, setLoading } = props;
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Checks if the keypress was 'Enter', if so, simulates clicking the "Login" button
     * If the "Back" button is selected, this function does nothing and the back button is clicked instead
     * @param e the keyboard event
     */
    function handlePressEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter' &&
            document.activeElement !== document.getElementById('backBtn')) {

            submitBasicAuth();
        }
    }

    /**
     * Contacts the authentication server to attempt a login. If login is successful, we route to the
     * authenticated landing page or the MFA screen depending on the response. Otherwise, we set the error message appropriately.
     */
    function submitBasicAuth(): void {

        const loginForm = {
            principal: username,
            secret: password,
            authenticatorCode: '',
            captchaResponse: '',
            authenticationType: ServerAuthType.BASIC
        }

        setLoading(true);

        axios.post<BasicAuthResponse>('auth/authenticate/login', loginForm)
            .then((response) => {
                const basicAuthResponse = response.data;

                // If mfa is needed, we render mfa screen by setting auth type appropriately
                if (basicAuthResponse.needsTwoFactor) {
                    setErrorMessage('');
                    setAuthType('mfa');
                    setLoading(false);
                } else { // Otherwise, OTK should be present. Use it to finish authentication
                    window.location.href = `${process.env.REACT_APP_CP_URL}/login?username=${encodeURIComponent(username)}&otk=${basicAuthResponse.oneTimeKey}`
                }
            })
            .catch((err) => {
                setLoading(false);

                if (err.response.data !== undefined && err.response.data.message !== undefined) {
                    setErrorMessage(err.response.data.message);
                }
                else {
                    setErrorMessage('Server error. Please try again.');
                }
            });
    }

    return <span onKeyPress={handlePressEnter}>
        <Link href="#" onClick={handleOpenModal} align="right"><Typography>Forgot your password?</Typography></Link>
        <Grid item>
            <StyledTextField
                autoFocus
                variant="outlined"
                id="password"
                type="password"
                value={password} onChange={(e) => setPassword(e.target.value)}
                fullWidth
                color="primary"
                placeholder="Enter Password"
                label="Password"
                margin="dense"
                error={errorMessage !== ''}
                helperText={errorMessage}
            />
        </Grid>
        <Grid item>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <SecondaryButton
                        id="backBtn"
                        onClick={handleReset}
                        type="button"
                        fullWidth
                        variant="contained"
                        startIcon={<ArrowRightAltIcon style={{ transform: "scaleX(-1)" }} />}
                    >
                        Back
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <PrimaryButton
                        onClick={submitBasicAuth}
                        type="button"
                        fullWidth variant="contained"
                    >
                        Login
                </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    </span>
}

export default PasswordScreen;