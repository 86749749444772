import React from 'react';

type Orientation = "vertical" | "horizontal";

interface Props {
    size: number
    orientation: Orientation
}

/**
 * Creates a box of the specified height/width to fill space
 * @param props
 *     size (number): number of pixels to stretch the box
 *     orientation (Orientation): direction to stretch box (vertical/horizontal) 
 */
function Spacer(props: Props): JSX.Element {

    const { orientation, size } = props;

    return (orientation === 'vertical')
        ? <div style={{ height: size }}></div>
        : <div style={{ width: size }}></div>
        ;
}

export default Spacer;