import axios from 'axios';
import React, { useEffect } from 'react';
import { AuthType } from '../../types/TypeRegistry';

interface Props {
    email: string,
    setErrorMsg: (errorMesg: string) => void,
    setAuthType: (authType: AuthType) => void,
    setLoading: (loading: boolean) => void
}

/**
 * Window which kicks off SAML authentication
 * 
 * @param props
 *     email (string): email address to perform SAML authentication for
 *     setErrorMsg (callback): parent managing error message state, setter must come down as prop
 *     setAuthType (callback): parent managing authentication type state, setter must come down as prop
 *     setLoading (callback): parent managing loading state, setter must come down as prop
 */
function SamlWindow(props: Props): JSX.Element {

    const { email, setErrorMsg, setAuthType, setLoading } = props;
    useEffect(() => authenticateSaml(), [email]);

    /**
    * Makes a request to the authentication server to kick off SAML authentication
    */
    function authenticateSaml(): void {

        const requestUri = `auth/authenticate/saml?email=${encodeURIComponent(email)}`;
        setLoading(true);

        axios.get(requestUri)
            .then((response) => {
                window.location.href = `${process.env.REACT_APP_SAML_URL}?otk=${response.data}`;
            }).catch((err) => {
                setLoading(false);
                if (err.response.data !== undefined && err.response.data.violations !== undefined) {
                    setErrorMsg(err.response.data.message);
                } else {
                    setErrorMsg('Server error. Please try again.');
                }
                setAuthType('none');
            });
    }

    return <>
    </>;

}


export default SamlWindow;