import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import ClientShowcase from './ClientShowcase';
import LoginWindow from './LoginWindow';

/**
 * Window containing entire Login App
 * Split into two halves- the login contents and the client showcase
 * For large screens the halves are split vertically, for smaller screens they are split horizontally
 */
function MainWindow(): JSX.Element {
    const styles = useStyles();
    return <Grid className={styles.container} container >
        <Grid item xs={12} sm={12} md={6}>
            <LoginWindow />
        </Grid>
        <Grid className={styles.rightSide} item xs={12} sm={12} md={6}>
            <ClientShowcase />
        </Grid>
    </Grid>;
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        minHeight: '100vh'
    },
    rightSide: {
        backgroundColor: '#0068A8'
    }
});


export default MainWindow;