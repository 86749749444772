import * as React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Creates material-ui theme which is passed to the rest of the app via ThemeProvider
const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 12,
    button: {
      fontSize: 14,
      textTransform: "none"
    },
  },
  palette: {
    primary: {
      main: "#005B99"
    },
    secondary: {
      main: "#8D9BAA",
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        background: "rgba(232, 240, 254, 1)",
        height: '44',
        "&$focused": {
          backgroundColor: "#fffff",
        },
      },
      focused: {}
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
