import * as React from 'react';
import MainWindow from './components/login/MainWindow';

/**
 * Application starting point
 */
function App(): JSX.Element {
  return (
    <div className="App">
      <MainWindow />
    </div>
  );
}

export default App;
