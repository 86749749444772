import { Grid, Link, Typography } from '@material-ui/core';
import React, { useState, KeyboardEvent } from 'react';
import PrimaryButton from '../styled/PrimaryButton';
import SecondaryButton from '../styled/SecondaryButton';
import StyledTextField from '../styled/StyledTextField';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { ServerAuthType } from '../../types/TypeRegistry';
import { BasicAuthResponse } from './PasswordScreen';
import axios from 'axios';
import EmergencyKeyModal from './EmergencyKeyModal';

interface Props {
    username: string,
    password: string,
    handleReset: () => void,
    setLoading: (loading: boolean) => void
}

/**
 * Displays a field for the authenticator code if the user requires multi factor auth
 * When 'Login' button is clicked, collected info is sent to authentication server to attempt a login
 * 
 * @param props 
 *     username (string): username that was entered at the previous screen
 *     password (string): user's password for authentication
 *     handleReset (callback): resets form, to be executed when 'cancel' is clicked
 *     setLoading (callback): parent managing loading state, setter must come down as prop
 */
function MultiFactorScreen(props: Props): JSX.Element {

    const { username, password, handleReset, setLoading } = props;

    const [mfaCode, setMfaCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emergencyModalOpen, setEmergencyModalOpen] = useState(false);

    /**
     * Checks if the keypress was 'Enter', if so, simulates clicking the "Login" button
     * If the "Back" button is selected, this function does nothing and the back button is clicked instead
     * 
     * @param e the keyboard event
     */
    function handlePressEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter' &&
            document.activeElement !== document.getElementById('backBtn')) {

            submitMultiFactorAuth();
        }
    }

    /**
    * Contacts the authentication server to attempt a login. If login is successful, we route to the
    * authenticated landing page. Otherwise, we set the error message appropriately.
    */
    function submitMultiFactorAuth(): void {

        const loginForm = {
            principal: username,
            secret: password,
            authenticatorCode: mfaCode,
            captchaResponse: '',
            authenticationType: ServerAuthType.MFA
        }

        setLoading(true);

        axios.post<BasicAuthResponse>('auth/authenticate/login', loginForm)
            .then((response) => {
                const basicAuthResponse = response.data;
                window.location.href = `${process.env.REACT_APP_CP_URL}/login?username=${encodeURIComponent(username)}&otk=${basicAuthResponse.oneTimeKey}`;
            })
            .catch((err) => {
                setLoading(false);

                if (err.response.data !== undefined && err.response.data.message !== undefined) {
                    setErrorMessage(err.response.data.message);
                }
                else {
                    setErrorMessage('Server error. Please try again.');
                }
            });
    }

    return <span onKeyPress={handlePressEnter}>
        <Link href="#"
            onClick={() => setEmergencyModalOpen(true)}
            align="right">
            <Typography>Unable to provide code?</Typography>
        </Link>
        <Grid item>
            <StyledTextField
                autoFocus
                variant="outlined"
                id="mfaCode" value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value)}
                fullWidth
                color="primary"
                placeholder="Enter Code"
                label="Two-Factor Authentication"
                margin="dense"
                error={errorMessage !== ''}
                helperText={errorMessage}
            />
        </Grid>
        <Grid item>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <SecondaryButton
                        id="backBtn"
                        onClick={handleReset}
                        type="button"
                        fullWidth
                        variant="contained"
                        startIcon={<ArrowRightAltIcon style={{ transform: "scaleX(-1)" }} />}
                    >
                        Back
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <PrimaryButton
                        onClick={submitMultiFactorAuth}
                        type="button"
                        fullWidth variant="contained"
                    >
                        Login
                </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
        <EmergencyKeyModal
            username={username}
            password={password}
            open={emergencyModalOpen}
            handleReset={handleReset}
            handleClose={() => setEmergencyModalOpen(false)} />

    </span>
}

export default MultiFactorScreen;