import React from "react";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

interface Props {
  source: string;
}

/**
 * Basic image wrapped with some styling that resizes itself in extra small displays
 *
 * @param props
 *     source (string): image source
 */
function ClientLogo(props: Props): JSX.Element {
  const { source } = props;
  const styles = useStyles();
  return <img src={source} alt="" className={styles.logo} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.only("xs")]: {
        height: 60,
        width: 60,
      },
      [theme.breakpoints.up("sm")]: {
        height: 96,
        width: 96,
      },
    },
  })
);

export default ClientLogo;
