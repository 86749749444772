import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import betterbusinessbureau from "../../resources/img/betterbusinessbureau.png";
import everlast from "../../resources/img/everlast.png";
import ihop from "../../resources/img/ihop.png";
import inbox from "../../resources/img/inbox.png";
import kissmetrics from "../../resources/img/kissmetrics.png";
import movado from "../../resources/img/movado.png";
import nationalinstruments from "../../resources/img/nationalinstruments.png";
import saksfifthavenue from "../../resources/img/saksfifthavenue.png";
import sling from "../../resources/img/sling.png";
import sourceforge from "../../resources/img/sourceforge.png";
import splunk from "../../resources/img/splunk.png";
import square from "../../resources/img/square.png";
import underarmour from "../../resources/img/underarmour.png";
import vizio from "../../resources/img/vizio.png";
import ClientLogo from "../styled/ClientLogo";

function LogoCollage(): JSX.Element {
  const imageSources = [
    everlast,
    betterbusinessbureau,
    ihop,
    inbox,
    kissmetrics,
    movado,
    nationalinstruments,
    saksfifthavenue,
    sling,
    sourceforge,
    splunk,
    square,
    underarmour,
    vizio,
  ];

  // Shuffle image order
  for (let i = imageSources.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [imageSources[i], imageSources[j]] = [imageSources[j], imageSources[i]];
  }

  const [loading, setLoading] = useState(true);

  // Only set "loading" to false after all of the images have finished loading.
  useEffect(() => {
    let loaded = 0;
    imageSources.forEach((source) => {
      const image = new Image();
      image.src = source;
      image.onload = () => {
        if (++loaded === 14) {
          setLoading(false);
        }
      };
    });
  }, []);

  if (loading) {
    return <span />;
  } else {
    // Formula for arrangement: shortestColumn = (imageCount - 4) / 5
    // Works evenly if imageCount - 4 is divisible by 5
    // TODO: Use formula to render
    return (
      <Grid container alignItems="center">
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ClientLogo source={imageSources[0]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[1]} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ClientLogo source={imageSources[2]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[3]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[4]} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ClientLogo source={imageSources[5]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[6]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[7]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[8]} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ClientLogo source={imageSources[9]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[10]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[11]} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ClientLogo source={imageSources[12]} />
            </Grid>
            <Grid item>
              <ClientLogo source={imageSources[13]} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default LogoCollage;
