import React, { KeyboardEvent, useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StyledTextField from '../styled/StyledTextField';
import SecondaryButton from '../styled/SecondaryButton';
import PrimaryButton from '../styled/PrimaryButton';
import Loading from '../util/Loading';

interface Props {
    open: boolean
    handleClose: () => void
}

const EMAIL_REGEX = /\S+@\S+\.\S+/;

/**
 * Modal which prompts user for email in order to help retrieve/reset username/password information.
 * @param props 
 */
function ForgotInfoModal(props: Props): JSX.Element {
    const { open, handleClose } = props;

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // If an error message is present, errorStatus is true
    const errorStatus = (errorMessage !== '');

    const styles = useStyles();

    function closeModal(): void {
        handleClose();
        setEmail('');
        setErrorMessage('');
        setFormSubmitted(false);
    }

    function handleSend(): void {
        if (email.match(EMAIL_REGEX)) {
            setLoading(true);
            axios.get(`auth/user/forgot?email=${encodeURIComponent(email)}`)
                .then(() => {
                    setFormSubmitted(true);
                })
                .catch(err => {
                    console.error(err);
                    setErrorMessage('Server error. Please try again.');
                })
                .finally(() => setLoading(false));
        } else {
            setErrorMessage('Invalid email address. Please us a valid email address format.');
        }
    }

    function handlePressEnter(e: KeyboardEvent): void {
        if (e.key === 'Enter'
            && document.activeElement !== document.getElementById('cancelBtn')) {

            handleSend();
        }
    }

    return <Dialog
        open={open}
        onClose={closeModal}
        onKeyPress={handlePressEnter}
    >
        {loading
            ? <Loading />
            : <span className={styles.contents}>
                <Grid container justify="center" direction="column" spacing={2}>
                    <Grid item>
                        <DialogTitle><b>Username/Password Recovery</b></DialogTitle>
                    </Grid>
                    {renderContents()}
                    <Grid item>
                        <Typography>Not receiving username/password recovery email? Email us at <Link href="mailto:sales@dnsmadeeasy.com">sales@dnsmadeeasy.com</Link></Typography>
                    </Grid>
                </Grid>
            </span>
        }

    </Dialog>

    function renderContents(): JSX.Element {

        if (!formSubmitted) {
            return <>
                <Grid item>
                    <Typography>Please provide the email used for your account. We will send you your username along with a link to use if you would like to do a password reset.</Typography>
                </Grid>
                <Grid item>
                    <StyledTextField
                        autoFocus
                        variant="outlined"
                        fullWidth color="primary"
                        placeholder="Enter Email"
                        label="Email"
                        margin="dense"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errorStatus}
                        helperText={errorMessage}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <SecondaryButton
                                id="backBtn"
                                onClick={closeModal}
                                type="button"
                                fullWidth
                                variant="contained"
                            >
                                Cancel
                        </SecondaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                onClick={handleSend}
                                type="button"
                                fullWidth variant="contained"
                            >
                                Send
                        </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        } else {
            return <>
                <Grid item className={styles.successMessageContainer}>
                    <Typography align="center" className={styles.successMessage}>Your username and a link for password reset have been sent to <b>{email}</b>.</Typography>
                </Grid>
            </>
        }
    }
}

const useStyles = makeStyles({
    contents: {
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    },
    successMessageContainer: {
        backgroundColor: 'rgba(229, 244, 227, 1)'
    },
    successMessage: {
        padding: '1rem',
    }
});

export default ForgotInfoModal;